import axios from "axios"

export default {
    state: {
        user: null,
        preferences: {
            preferedSaveAction: "save_and_new_item"
        }
    },
    actions: {
        // async Register({dispatch}, form) {
        //     let res = await axios.post(
        //         "http://142.147.105.159:4000/api/v1/auth/signup", 
        //         form
        //     )
        //     console.log("register", res);

        //     await dispatch('LogIn', form)
        // },
        async refreshAuth({dispatch, state}, refresh = true) {
            if (state.user) {
                await dispatch("LogIn", state.user)
                if (state.user && refresh) {
                    bus.$emit("snackbarAdd", {text: "Token expired. We will refresh a page in a moment", color: "error"})
                    setTimeout(() => {
                        location.reload();
                    }, 5000)
                }
            }
        },
        async LogInAs({commit}, userId) {
            let res = await axios.get(
                `${process.env.VUE_APP_AUTH_URI}/api/v1/auth/token?userId=${userId}`, 
                {
                    headers: {
                        Authorization: `Bearer ${this.getters.userSessionToken}`
                    }
                }
            )
            if (res.status == 200) {
                console.log(res)
                let user = {
                    sessionToken: res.data.data.apiKey,
                    role: res.data.data.role
                }
                // form = Object.assign(form, res.data.data)
                // await commit('setUser', form)
                // return true;    
            } else {
                bus.$emit("snackbarAdd", {text: res.response.data.message, color: "error"})
            }
        },
        async LogIn({commit}, form) {
            let res = null;
            try {
                res = await axios.post(
                    `${process.env.VUE_APP_AUTH_URI}/api/v1/auth/login`, 
                    form
                )
            } catch (e) {
                res = e;
            }
            if (res.status == 200) {
                form = Object.assign(form, res.data.data)
                await commit('setUser', form)
                return true;    
            }
            await commit('LogOut')
            return res?.response?.data?.message ? res.response.data.message : false;
        },
        async LogOut({commit}){
            commit('LogOut')
        }
    },
    mutations: {
        setUser(state, form){
            state.user = form
        },
        LogOut(state){
            state.user = null
        },
        setPreferedSaveAction(state, preferedSaveAction) {
            state.preferences.preferedSaveAction = preferedSaveAction
        }
    },
    getters: {
        isAuthenticated: state => !!state.user,
        isAdmin: state => !!state.user && state.user.role == "admin",
        StateUser: state => state.user,
        userSessionToken: state => state.user.sessionToken,

        preferedSaveAction: state => state.preferences.preferedSaveAction,
    }
}