<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
      v-if="$store.getters.isAuthenticated"
    >
      <v-list
        dense
        nav
      >
        <v-list-item
          link
          @click="$router.push({name: 'dashboard'})"
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          @click="$router.push({name: 'proxies'})"
        >
          <v-list-item-icon>
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Proxies</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$store.getters.isAdmin"
          link
          @click="$router.push({name: 'users'})"
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app v-if="$store.getters.isAuthenticated">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Reseller system</v-toolbar-title>

      <v-spacer></v-spacer>

      <span class="user_email">
        {{ $store.getters.StateUser.email }}
      </span>
      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar
              color="indigo"
              size="36"
            >
              <span class="white--text text-h6">{{ $store.getters.StateUser.email.substring(0, 2) }}</span>
            </v-avatar>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="logout"
          >
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
    <v-snackbar
      v-model="snackbars[0]"
      v-if="snackbars[0]"
    >
      {{ snackbars[0].text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbars[0].color"
          text
          @click="snackbars.shift()"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
  export default {
    data: () => ({ 
      drawer: null,
      snackbars: []
    }),
    watch: {
      snackbars(oldVal, newVal) {
        let newSnackbars = this.snackbars.filter(item => item !== false)
        if (newSnackbars.length != this.snackbars.length) {
          this.snackbars = newSnackbars
        }
      }
    },
    mounted() {
      bus.$on("snackbarAdd", this.snackbarAdd)
    },
    methods: {
      logout() {
        this.$store.dispatch("LogOut");
        this.$router.push({name: 'login'})
      },
      snackbarAdd({text, color = "success"}) {
        this.snackbars.push({
          text:text, 
          color: color
        })
      }
    }
  }
</script>