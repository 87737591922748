import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Login.vue'),
    meta: { requiresGuest: true },
  },
  {
    path: "/",
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/documentation/how-to-connect-to-proxies',
    name: 'how-to-connect-to-proxies',
    component: () => import(/* webpackChunkName: "about" */ '../views/HowToConnectToProxies.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/proxies',
    name: 'proxies',
    component: () => import(/* webpackChunkName: "about" */ '../views/Proxy/Proxies.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/proxy/:proxyId/collections-manager',
    name: 'proxy-collections-manager',
    component: () => import(/* webpackChunkName: "about" */ '../views/Proxy/CollectionsManager.vue'),
    meta: { requiresAuth: true },
  },
  // {
  //   path: '/proxies/add',
  //   name: 'proxy-add',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Proxy/AddProxy.vue'),
  // },
  // {
  //   path: '/proxies/bulk/add',
  //   name: 'proxy-add-bulk',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Proxy/AddBulk.vue'),
  // },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "about" */ '../views/User/Users.vue'),
    meta: { requiresAuth: true, isAdmin: true },
  },
  {
    path: '/user/add',
    name: 'user-add',
    component: () => import(/* webpackChunkName: "about" */ '../views/User/AddUser.vue'),
    meta: { requiresAuth: true, isAdmin: true },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/login')
  }
  if(to.matched.some(record => record.meta.requiresGuest)) {
    if (!store.getters.isAuthenticated) {
      next()
      return
    }
    next({name: 'dashboard'})
  }
  if(to.matched.some(record => record.meta.isAdmin)) {
    if (!store.getters.StateUser.role == "admin") {
      next()
      return
    }
    next({name: 'dashboard'})
  }
  next()
})

export default router
