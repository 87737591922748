import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './assets/scss/app.scss'
import axios from "axios"

window.bus = new Vue();

//Handle exeption
axios.interceptors.response.use(response => {
  return response;
}, async error => {
 if (error.response.status === 401) {
  await store.dispatch("refreshAuth")
 }
 return error;
});

//prevent exception from above
function checkToken() {
  if (store.getters.StateUser && store.getters.StateUser.tokenExpirationTimestamp - Date.now() < 30000) {
    store.dispatch("refreshAuth", false)
  }
}
checkToken();
setInterval(checkToken, 30000)


Vue.config.productionTip = false


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
