<template>
  <div class="mx-3 my-5">
    <v-card
      elevation="2"
    >
      <v-card-title>
        <h1>Welcome!</h1>
      </v-card-title>
      <v-card-text>
        <h3>Tutorials and resorces:</h3>
        <ul>
          <li><router-link to="/documentation/how-to-connect-to-proxies">How to connect to proxies</router-link></li>
          <li><a href="https://blazing-reseller.readme.io/" target="_blank">Reseller API</a></li>
        </ul>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Home',

    components: {
    },
  }
</script>

<style>
a {
  color: #7c69ef !important;
}
</style>